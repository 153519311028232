/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref } from "vue";

import axios from 'axios';
import CONST from "@/services/Const";


export interface FileUpload {
    status: boolean;
    keyBase10: number;
    keyBase36: string;
    directoryName: number;
    expirsDate: Date;
    expirsDateStr: string;
}

export default defineComponent({
    name: "Home",
    components: {
    },
    setup() {
        const SelectValidDay = ref(7);
        const Uploading = ref(false);
        const Progress = ref(0);
        const KeyBase36 = ref("");
        const ExpirsDate = ref("");
        const ErrorMessage = ref("");
        const AudioFileName = ref("");
        const IsError = ref(false);
        const IsDefult = ref(true);
        const IsFileSelected = ref(false);
        const IsUploading = ref(false);
        const IsCompletion = ref(false);
        const IsModalViewFirst = ref(false);
        const IsModalViewSetting = ref(false);
        const IsModalViewShare = ref(false);
        const PlayerUrlEnc = ref("");
        const ExpirsDateEnc = ref("");
        const IsPCBrowser = ref(false);
        const IsIPhone = ref(false);

        let file: any = null;
        let inputFileType = ref(null);
        let inputFileSize = ref(null);

        if (!(navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0)) { IsPCBrowser.value = true; }

        if (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('iPad') > 0) { IsIPhone.value = true; }
        if (IsIPhone.value == true) {
            IsError.value = true;
            ErrorMessage.value = "アプリをインストールしてください";

        }

        async function onSelectFile(e: any) {



            const files = e.target.files;
            if (files.length === 0) {
                file = null;
                IsDefult.value = true;
                IsFileSelected.value = false;
                return;
            }
            try {
                if (e != null) {
                    e.preventDefault();
                    const files = e.target.files;
                    inputFileType = files[0].type;
                    inputFileSize = files[0].size;
                    AudioFileName.value = files[0].name;
                    IsFileSelected.value = true;
                    IsDefult.value = false;

                    await isCorrectFileCheker(inputFileType, inputFileSize);

                } else {
                    console.log("error90");
                }
            } catch {
                console.log("error93");

            }
            file = files[0];


        }

        async function isCorrectFileCheker(fileType: any, fileSize: any) {
            if (fileSize > 21474836) {

                IsError.value = true;
                IsDefult.value = true;
                IsFileSelected.value = false;
                ErrorMessage.value = "20M以上のファイルは送信できません";
            } else if (fileType.startsWith('audio')) {
                IsError.value = false;
            } else {

                IsError.value = true;
                IsDefult.value = true;
                IsFileSelected.value = false;
                ErrorMessage.value = "このファイルは送信できません";
            }
        }

        function onUploadSubmit() {
            IsUploading.value = true;
            IsFileSelected.value = false;
            const formData = new FormData();
            formData.append('postedFile', file);

            Uploading.value = true;

            let result: FileUpload = {
                status: false,
                keyBase10: 0,
                keyBase36: "",
                directoryName: 0,
                expirsDate: new Date(),
                expirsDateStr: ""
            };
            axios.post(CONST.API_URI + `FileUpload?days=${SelectValidDay.value}`, formData, { onUploadProgress: onUpload }).then(({ data }) => {
                result = data;
            }).catch(() => {

                // エラーが発生

            })
                .then(() => {
                    Progress.value = 0;  // プログレスをクリア
                    Uploading.value = false;
                    onComplete(result.keyBase36, result.expirsDateStr);
                }
                );

        }

        function onUpload(e: any) {
            // プログレスバーを計算して変更
            Progress.value = Math.floor((e.loaded * 100) / e.total);
        }

        function onComplete(keyBase36: string, expirsDateStr: string) {
            IsUploading.value = false;
            IsCompletion.value = true;
            KeyBase36.value = "https://s.linemp3.com/" + keyBase36;
            PlayerUrlEnc.value = encodeURIComponent(KeyBase36.value);


            ExpirsDate.value = expirsDateStr;
            ExpirsDateEnc.value = encodeURIComponent("有効期限: " + expirsDateStr);
        }

        function openModalFirst() {
            console.log("open modal");
            IsModalViewFirst.value = true;
        }


        function openModalSetting() {
            console.log("open modal setting");
            IsModalViewSetting.value = true;
        }

        function openModalShare() {
            console.log("open modal share");
            IsModalViewShare.value = true;
        }

        function closeModal() {
            IsModalViewFirst.value = false;
            IsModalViewSetting.value = false;
            IsModalViewShare.value = false;
        }




        // function reset() {
        //     file = null;
        //     Uploading.value = false;
        //     Progress.value = 0;
        //     KeyBase36.value = "";
        //     ExpirsDate.value = "";
        //     ErrorMessage.value = "";
        //     IsError.value = false;
        //     IsDefult.value = true;
        //     IsFileSelected.value = false;
        //     IsUploading.value = false;
        //     IsCompletion.value = false;
        //     AudioFileName.value = "";

        //     inputFileType.value = null;
        //     inputFileSize.value = null;
        // }



        return {
            AudioFileName,
            SelectValidDay,
            Progress,
            Uploading,
            KeyBase36,
            ExpirsDate,
            IsDefult,
            IsError,
            IsFileSelected,
            IsUploading,
            IsCompletion,
            IsModalViewFirst,
            IsModalViewSetting,
            IsModalViewShare,
            IsPCBrowser,
            ErrorMessage,
            PlayerUrlEnc,
            ExpirsDateEnc,
            onSelectFile,
            onUploadSubmit,
            openModalFirst,
            openModalSetting,
            openModalShare,
            closeModal
        };
    },
});